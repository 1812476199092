import React from 'react';

import SEO from '../components/seo';

const Home = () => {

	return (
		<section className="contact">
			<SEO
				title="Contact"
				description=""
			/>

			<h2>Contact</h2>

			<form>
				<input type="text" placeholder="Nom et prénom*" required />
				<input type="email" placeholder="Email*" required />
				<input type="text" placeholder="Téléphone" />
				<textarea placeholder="Votre message*" />
				<button type="submit">Envoyer</button>
			</form>
		</section>
	);
};

export default Home;
